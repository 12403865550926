@media screen and (width <= 1225px) {
  body {
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden !important;
  }

  .cube-container {
    flex-direction: column;
  }

  .span {
    display: inline-block;
  }

  .threecontainer {
    z-index: -100;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  .threecontainer canvas {
    width: 100vw;
    height: 100vh;
  }

  .youtube-player {
    scale: 1.5;
  }

  .image-container-landing {
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    background-color: #fff9;
    border: 2px solid #2e2e2e;
    border-radius: calc(1rem + 1vw);
    transition: background .2s linear;
    position: relative;
    scale: .35;
  }

  .image-container-landing:before {
    font-size: calc(1rem + 1vw);
    font-family: var(--main-font);
    border-radius: calc(30px + 5vw);
    justify-content: center;
    align-items: center;
    width: 180%;
    height: 30%;
    transition: background .2s linear;
    position: absolute;
  }

  #marquee-container {
    z-index: 10000;
    white-space: nowrap;
    background-color: #2e2e2e;
    width: 100vw;
    position: fixed;
    overflow: hidden;
  }

  .image-container-landing img {
    max-width: 23vw;
    max-height: 23vw;
    padding: 20px;
  }

  .image-container-landing:active {
    background-color: #fff;
  }

  .iban-landing-img {
    scale: .55;
  }

  .iban-info-img {
    scale: .65;
  }

  .image-container-landing:first-of-type, .image-container-landing:nth-of-type(2) {
    transform: translateX(0);
  }

  #marquee-container {
    z-index: 10000;
    white-space: nowrap;
    background-color: #2e2e2e;
    width: 100vw;
    height: calc(2rem + 3vh);
    position: fixed;
    overflow: hidden;
  }

  #marquee-content {
    z-index: 10000;
    color: #fff;
    font-family: var(--marquee-font), sans-serif;
    text-transform: uppercase;
    flex-direction: row;
    align-items: center;
    gap: 20vw;
    width: 100%;
    height: 100%;
    padding-top: 5px;
    padding-left: 100%;
    font-size: calc(1vw + 1rem);
    font-weight: 800;
    animation: 60s linear infinite marquee;
    display: flex;
  }

  .reddot {
    width: calc(.6vw + .3rem);
    height: calc(.6vw + .3rem);
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-400%);
    }
  }

  .footer-button {
    text-align: center;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    width: calc(4rem + 10vw);
    padding: 10px 8px;
    font-size: calc(.4rem + .4vw);
    font-weight: 800;
    transition: color .2s linear, background-color .2s linear, transform .1s linear, box-shadow .1s linear;
    display: flex;
    scale: 1.1;
  }

  .footer-button:hover {
    transform: translateY(0)translateX(0);
    box-shadow: 0 0 #2e2e2e;
  }

  footer {
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 6.5rem;
    padding: 0 3vw 6vh;
    display: flex;
    position: relative;
    top: 90vh !important;
  }

  .footer-right, .footer-left {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 30%;
    height: 100%;
    display: flex;
  }

  footer p {
    font-family: var(--main-font), sans-serif;
    text-align: center;
    align-items: center;
    max-width: calc(3rem + 4.5vw);
    font-size: calc(.4rem + .4vw);
    font-weight: 600;
    display: flex;
  }

  .overlay h1 {
    font-family: var(--marquee-font);
    color: var(--black);
    letter-spacing: -.1rem;
    text-transform: uppercase;
    margin-top: 2rem;
    font-size: calc(2rem + 2vw);
  }

  .overlay h2 {
    text-align: center;
    hyphens: auto;
    width: 75%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 30px;
  }

  .img-container {
    justify-content: center;
    gap: 10px;
    width: 70%;
    height: calc(5rem + 5vh);
    display: flex;
    transform: translateY(.8vh);
  }

  .img-container img {
    width: calc(5rem + 6vw);
    height: calc(5rem + 6vw);
  }

  .image-div-for-before {
    justify-content: center;
    height: calc(4rem + 6vw);
    transition: transform 1s cubic-bezier(.3, -.5, .2, 1);
    display: flex;
  }

  .image-div-for-before:before {
    text-align: center;
    margin-top: 1.8vh;
    font-size: calc(1rem + .2vw);
  }

  .copied-container {
    width: calc(4rem + 6vw);
    height: calc(4rem + 6vw);
  }

  .landing-copied-container p {
    font-size: calc(1rem + 2vw);
  }

  .topic-anchor {
    font-size: 1rem;
  }
}

@media screen and (width <= 780px) {
  .stream-video {
    flex-direction: column !important;
  }
}

@media screen and (width <= 850px) {
  body {
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden !important;
  }

  .cube-container {
    flex-direction: column;
  }

  .span {
    display: inline-block;
  }

  .threecontainer {
    z-index: -100;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  .threecontainer canvas {
    width: 100vw;
    height: 100vh;
  }

  .stream-video {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70% !important;
  }

  .image-container-landing {
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    background-color: #fff9;
    border: 2px solid #2e2e2e;
    border-radius: calc(1rem + 1vw);
    transition: background .2s linear;
    position: relative;
    scale: .45;
  }

  .image-container-landing:before {
    font-size: calc(.5rem + 1vw);
    font-family: var(--main-font);
    border-radius: calc(30px + 5vw);
    justify-content: center;
    align-items: center;
    width: 180%;
    height: 10%;
    transition: background .2s linear;
    position: absolute;
  }

  #marquee-container {
    z-index: 10000;
    white-space: nowrap;
    background-color: #2e2e2e;
    width: 100vw;
    position: fixed;
    overflow: hidden;
  }

  .image-container-landing img {
    max-width: calc(6rem + 8vw);
    max-height: calc(6rem + 8vw);
    padding: 20px;
  }

  .image-container-landing:active {
    background-color: #fff;
  }

  .iban-landing-img {
    scale: .65;
  }

  .iban-info-img {
    scale: .75;
  }

  .image-container-landing:first-of-type, .image-container-landing:nth-of-type(2) {
    transform: translateX(0);
  }

  #marquee-container {
    z-index: 10000;
    white-space: nowrap;
    background-color: #2e2e2e;
    width: 100vw;
    height: calc(2rem + 3vh);
    position: fixed;
    overflow: hidden;
  }

  #marquee-content {
    z-index: 10000;
    color: #fff;
    font-family: var(--marquee-font), sans-serif;
    text-transform: uppercase;
    flex-direction: row;
    align-items: center;
    gap: 20vw;
    width: 100%;
    height: 100%;
    padding-top: 5px;
    padding-left: 100%;
    font-size: calc(1vw + 1rem);
    font-weight: 800;
    animation: 60s linear infinite marquee;
    display: flex;
  }

  .reddot {
    width: calc(.6vw + .3rem);
    height: calc(.6vw + .3rem);
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-400%);
    }
  }

  .footer-button {
    text-align: center;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    width: calc(4rem + 10vw);
    padding: 10px 8px;
    font-size: calc(.4rem + .4vw);
    font-weight: 800;
    transition: color .2s linear, background-color .2s linear, transform .1s linear, box-shadow .1s linear;
    display: flex;
    scale: 1.1;
  }

  .footer-button:hover {
    transform: translateY(0)translateX(0);
    box-shadow: 0 0 #2e2e2e;
  }

  footer {
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 6.5rem;
    padding: 0 3vw 10vh;
    display: flex;
    position: relative;
  }

  .footer-right, .footer-left {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 30%;
    height: 100%;
    display: flex;
  }

  footer p {
    font-family: var(--main-font), sans-serif;
    text-align: center;
    align-items: center;
    max-width: calc(3rem + 4.5vw);
    font-size: calc(.4rem + .4vw);
    font-weight: 600;
    display: flex;
  }

  .overlay h1 {
    font-family: var(--marquee-font);
    color: var(--black);
    letter-spacing: -.1rem;
    text-transform: uppercase;
    margin-top: 5rem;
    font-size: calc(1.3rem + 2vw);
  }

  .overlay h2 {
    text-align: center;
    hyphens: auto;
    width: 75%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 300;
    line-height: 30px;
  }

  .img-container {
    justify-content: center;
    gap: 10px;
    width: 70%;
    height: calc(5rem + 5vh);
    display: flex;
    transform: translateY(.8vh);
  }

  .img-container img {
    width: calc(5rem + 6vw);
    height: calc(5rem + 6vw);
  }

  .image-div-for-before {
    justify-content: center;
    height: calc(4rem + 6vw);
    transition: transform 1s cubic-bezier(.3, -.5, .2, 1);
    display: flex;
  }

  .image-div-for-before:before {
    text-align: center;
    margin-top: 1.8vh;
    font-size: calc(1rem + .2vw);
  }

  .copied-container {
    width: calc(4rem + 6vw);
    height: calc(4rem + 6vw);
  }

  .landing-copied-container p {
    font-size: calc(1rem + 2vw);
  }

  .topic-anchor {
    font-size: .8rem;
  }
}

@media screen and (width <= 540px) {
  body {
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden !important;
  }

  .cube-container {
    flex-direction: column;
  }

  .span {
    display: inline-block;
  }

  .threecontainer {
    z-index: -100;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  .threecontainer canvas {
    width: 100vw;
    height: 110vh;
  }

  .stream-video {
    flex-direction: column;
    justify-content: center;
    gap: 10vh;
    width: 100vw;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100vh !important;
  }

  #marquee-container {
    z-index: 10000;
    white-space: nowrap;
    background-color: #2e2e2e;
    width: 100vw;
    position: fixed;
    overflow: hidden;
  }

  .image-container-landing {
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    background-color: #fff9;
    border: 2px solid #2e2e2e;
    border-radius: calc(.8rem + 1vw);
    transition: background .2s linear;
    position: relative;
    scale: .85;
  }

  .image-container-landing:active {
    background-color: #fff;
  }

  .image-container-landing img {
    max-width: 20vw;
    max-height: 20vw;
    padding: 10px;
  }

  .iban-landing-img {
    scale: .75;
  }

  .iban-info-img {
    scale: .65;
  }

  .image-container-landing:first-of-type, .image-container-landing:nth-of-type(2) {
    transform: translateX(0);
  }

  .image-container-landing:before {
    font-size: calc(.5rem + .5vw);
    font-family: var(--main-font);
    border: 2px solid var(--black);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    text-align: center;
    background-color: #fff9;
    border-radius: calc(20px + 5vw);
    justify-content: center;
    align-items: center;
    width: 150%;
    height: 0%;
    transition: background .2s linear;
    display: flex;
    position: absolute;
    transform: translateX(-50%);
  }

  #marquee-container {
    z-index: 10000;
    white-space: nowrap;
    background-color: #2e2e2e;
    width: 100vw;
    height: calc(2rem + 3vh);
    position: fixed;
    overflow: hidden;
  }

  #marquee-content {
    z-index: 10000;
    color: #fff;
    font-family: var(--marquee-font), sans-serif;
    text-transform: uppercase;
    flex-direction: row;
    align-items: center;
    gap: 20vw;
    width: 100%;
    height: 100%;
    padding-top: 5px;
    padding-left: 100%;
    font-size: calc(1vw + .8rem);
    font-weight: 800;
    animation: 60s linear infinite marquee;
    display: flex;
  }

  .reddot {
    width: calc(.6vw + .3rem);
    height: calc(.6vw + .3rem);
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-400%);
    }
  }

  .footer-button {
    font-family: var(--main-font);
    color: var(--black);
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    text-transform: uppercase;
    text-align: center;
    background-color: #fff6;
    border: 1px solid #2e2e2e;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    width: calc(6rem + 10vw);
    height: 2.5rem;
    padding: 10px 8px;
    font-size: calc(.5rem + .4vw);
    font-weight: 800;
    transition: color .2s linear, background-color .2s linear, transform .1s linear, box-shadow .1s linear;
    display: flex;
    scale: .8;
    border-radius: none !important;
    outline: none !important;
  }

  .footer-button:hover {
    transform: translateY(0)translateX(0);
    box-shadow: 0 0 #2e2e2e;
  }

  footer {
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 6.5rem;
    padding: 0 3vw 6vh;
    display: flex;
    position: relative;
    bottom: auto;
    top: 75vh !important;
  }

  .footer-right, .footer-left {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    width: 30%;
    height: 100%;
    display: flex;
  }

  footer p {
    font-family: var(--main-font), sans-serif;
    text-align: center;
    align-items: center;
    max-width: calc(3rem + 4.5vw);
    font-size: calc(.4rem + .4vw);
    font-weight: 600;
    display: flex;
  }

  .overlay-container {
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10rem 0;
    display: flex;
  }

  .overlay h1 {
    letter-spacing: -.1rem;
    text-align: center;
    width: 100%;
    margin-top: 12rem;
    font-size: calc(1.3rem + 1.5vw);
    transform: translateY(1rem);
  }

  .overlay-anchor-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(1rem + 2vw);
    width: 60%;
    padding: calc(1rem + 2vh) 0;
    display: flex;
    transform: translateY(calc(.5rem + 1vh));
  }

  .overlay h2 {
    text-align: center;
    width: 75%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: .8rem;
    font-weight: 300;
    line-height: 23px;
  }

  .topic-anchor {
    height: 60%;
    padding: 10px 0;
    font-size: .8rem;
  }

  .img-container {
    justify-content: center;
    gap: 10px;
    width: 70%;
    height: calc(5rem + 5vh);
    display: flex;
    transform: translateY(.8vh);
  }

  .img-container img {
    width: calc(4rem + 6vw);
    height: calc(4rem + 6vw);
  }

  .image-div-for-before {
    justify-content: center;
    height: calc(4rem + 6vw);
    transition: transform 1s cubic-bezier(.3, -.5, .2, 1);
    display: flex;
  }

  .image-div-for-before:before {
    font-size: calc(.6rem + .2vw);
  }

  .copied-container {
    width: calc(4rem + 6vw);
    height: calc(4rem + 6vw);
  }

  .landing-copied-container p {
    font-size: calc(1rem + 2vw);
  }
}

/*# sourceMappingURL=index.c67856f5.css.map */
