/* TABLET TABLET LABTET TABLET */

@media screen and (max-width: 1225px) {


    body {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        overflow: hidden !important;

    }

    .cube-container {
        flex-direction: column;
    }


    .span {
        display: inline-block;
    }


    .threecontainer {
        height: 100vh;
        width: 100vw;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        z-index: -100;
        overflow: hidden;
    }

    .threecontainer canvas {
        height: 100vh;
        width: 100vw;
    }

    /* .stream-video {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh !important;
        flex-direction: column;
        justify-content: space-evenly;
    } */


    .youtube-player {
        scale: 1.5;
    }


    .image-container-landing {
        position: relative;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(100px);
        border-radius: calc(1rem + 1vw);
        scale: 0.35;
        border: 2px solid rgb(46, 46, 46);
        transition: background 0.2s linear;
    }

    .image-container-landing::before {

        font-size: calc(1rem + 1vw);
        font-family: var(--main-font);
        position: absolute;
        height: 30%;
        width: 180%;
        align-items: center;
        justify-content: center;
        border-radius: calc(30px + 5vw);
        transition: background 0.2s linear;
    }

    #marquee-container {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgb(46, 46, 46);

    }


    .image-container-landing img {

        padding: 20px;
        max-height: 23vw;
        max-width: 23vw;
    }

    .image-container-landing:active {
        background-color: rgba(255, 255, 255, 1);
    }



    .iban-landing-img {
        scale: 0.55;
    }

    .iban-info-img {
        scale: 0.65;
    }

    .image-container-landing:nth-of-type(1) {
        transform: translateX(0);
    }

    .image-container-landing:nth-of-type(2) {
        transform: translateX(0);
    }

    #marquee-container {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgb(46, 46, 46);
        height: calc(2rem + 3vh);
    }

    #marquee-content {
        z-index: 10000;
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        gap: 20vw;
        padding-left: 100%;
        width: 100%;
        animation: marquee 60s linear infinite;
        color: white;
        font-family: var(--marquee-font), sans-serif;
        font-size: calc(1vw + 1rem);
        font-weight: 800;
        text-transform: uppercase;

    }


    .reddot {
        height: calc(0.6vw + 0.3rem);
        width: calc(0.6vw + 0.3rem);
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-400%);
        }
    }



    /* footer */

    .footer-button {

        padding: 10px 8px;
        width: calc(4rem + 10vw);

        font-size: calc(0.4rem + 0.4vw);
        transition: color 0.2s linear, background-color 0.2s linear, transform 0.1s linear, box-shadow 0.1s linear;
        border-radius: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        scale: 1.1;
    }

    .footer-button:hover {
        transform: translateY(0vh) translateX(0vw);
        box-shadow: 0px 0px 0px rgb(46, 46, 46);
    }

    footer {
        position: relative;
        top: 90vh !important;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 3vw 6vh 3vw;
        height: 6.5rem;
    }

    .footer-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 30%;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;


    }

    .footer-left {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 30%;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;


    }

    footer p {
        font-family: var(--main-font), sans-serif;
        font-size: calc(0.4rem + 0.4vw);
        font-weight: 600;
        text-align: center;
        display: flex;
        align-items: center;
        max-width: calc(3rem + 4.5vw);
    }

    /* overlay */


    .overlay h1 {
        font-family: var(--marquee-font);
        margin-top: 2rem;
        color: var(--black);
        font-size: calc(2rem + 2vw);
        letter-spacing: calc(-1*(0.1rem));
        text-transform: uppercase;
    }

    .overlay h2 {
        font-family: Arial, Helvetica, sans-serif;
        width: 75%;
        text-align: center;
        hyphens: auto;
        font-size: 1.2rem;
        line-height: 30px;
        font-weight: 300;
    }

    .img-container {
        height: calc(5rem + 5vh);
        width: 70%;
        display: flex;
        justify-content: center;
        gap: 10px;
        transform: translateY(0.8vh);

    }

    .img-container img {
        height: calc(5rem + 6vw);
        width: calc(5rem + 6vw);
    }

    .image-div-for-before {
        height: calc(4rem + 6vw);
        display: flex;
        justify-content: center;
        transition: transform 1s cubic-bezier(0.3, -0.5, 0.2, 1);
    }

    .image-div-for-before::before {


        font-size: calc(1rem + 0.2vw);
        text-align: center;
        margin-top: 1.8vh;

    }

    .copied-container {

        height: calc(4rem + 6vw);
        width: calc(4rem + 6vw);
    }

    .landing-copied-container p {

        font-size: calc(1rem + 2vw);

    }

    .topic-anchor {
        font-size: 1rem;
    }
}


/* small tablet tabglelerlkjerlkj */


@media screen and (max-width: 780px) {

    .stream-video {
        flex-direction: column !important;
    }
}

@media screen and (max-width: 850px) {


    body {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        overflow: hidden !important;
    }

    .cube-container {
        flex-direction: column;
    }


    .span {
        display: inline-block;
    }


    .threecontainer {
        height: 100vh;
        width: 100vw;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        z-index: -100;
        overflow: hidden;
    }

    .threecontainer canvas {
        height: 100vh;
        width: 100vw;
    }

    .stream-video {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 70% !important;
        flex-direction: row;
        justify-content: space-evenly;
    }


    .image-container-landing {
        position: relative;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(100px);
        border-radius: calc(1rem + 1vw);
        scale: 0.45;
        border: 2px solid rgb(46, 46, 46);
        transition: background 0.2s linear;

    }

    .image-container-landing::before {

        font-size: calc(0.5rem + 1vw);
        font-family: var(--main-font);
        position: absolute;
        height: 10%;
        width: 180%;
        align-items: center;
        justify-content: center;
        border-radius: calc(30px + 5vw);
        transition: background 0.2s linear;
    }

    #marquee-container {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgb(46, 46, 46);

    }


    .image-container-landing img {

        padding: 20px;
        max-height: calc(6rem + 8vw);
        max-width: calc(6rem + 8vw);
    }

    .image-container-landing:active {
        background-color: rgba(255, 255, 255, 1);
    }



    .iban-landing-img {
        scale: 0.65;
    }

    .iban-info-img {
        scale: 0.75;
    }

    .image-container-landing:nth-of-type(1) {
        transform: translateX(0);
    }

    .image-container-landing:nth-of-type(2) {
        transform: translateX(0);
    }

    #marquee-container {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgb(46, 46, 46);
        height: calc(2rem + 3vh);
    }

    #marquee-content {
        z-index: 10000;
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        gap: 20vw;
        padding-left: 100%;
        width: 100%;
        animation: marquee 60s linear infinite;
        color: white;
        font-family: var(--marquee-font), sans-serif;
        font-size: calc(1vw + 1rem);
        font-weight: 800;
        text-transform: uppercase;

    }

    .reddot {
        height: calc(0.6vw + 0.3rem);
        width: calc(0.6vw + 0.3rem);
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-400%);
        }
    }



    /* footer */

    .footer-button {

        padding: 10px 8px;
        width: calc(4rem + 10vw);
        font-size: calc(0.4rem + 0.4vw);
        transition: color 0.2s linear, background-color 0.2s linear, transform 0.1s linear, box-shadow 0.1s linear;
        border-radius: 30px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        scale: 1.1;
    }

    .footer-button:hover {
        transform: translateY(0vh) translateX(0vw);
        box-shadow: 0px 0px 0px rgb(46, 46, 46);
    }

    footer {
        position: relative;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 3vw 10vh 3vw;
        height: 6.5rem;

    }

    .footer-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 30%;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;


    }

    .footer-left {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 30%;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;


    }

    footer p {
        font-family: var(--main-font), sans-serif;
        font-size: calc(0.4rem + 0.4vw);
        font-weight: 600;
        text-align: center;
        display: flex;
        align-items: center;
        max-width: calc(3rem + 4.5vw);
    }

    /* overlay */


    .overlay h1 {
        font-family: var(--marquee-font);
        margin-top: 5rem;
        color: var(--black);
        font-size: calc(1.3rem + 2vw);
        letter-spacing: calc(-1*(0.1rem));
        text-transform: uppercase;
    }

    .overlay h2 {
        font-family: Arial, Helvetica, sans-serif;
        width: 75%;
        text-align: center;
        hyphens: auto;
        font-size: 1rem;
        line-height: 30px;
        font-weight: 300;
    }

    .img-container {
        height: calc(5rem + 5vh);
        width: 70%;
        display: flex;
        justify-content: center;
        gap: 10px;
        transform: translateY(0.8vh);

    }

    .img-container img {
        height: calc(5rem + 6vw);
        width: calc(5rem + 6vw);
    }

    .image-div-for-before {
        height: calc(4rem + 6vw);
        display: flex;
        justify-content: center;
        transition: transform 1s cubic-bezier(0.3, -0.5, 0.2, 1);
    }

    .image-div-for-before::before {


        font-size: calc(1rem + 0.2vw);
        text-align: center;
        margin-top: 1.8vh;

    }

    .copied-container {

        height: calc(4rem + 6vw);
        width: calc(4rem + 6vw);
    }

    .landing-copied-container p {

        font-size: calc(1rem + 2vw);

    }

    .topic-anchor {
        font-size: 0.8rem;
    }
}



/* MOBILE MOBILE BOMILE MOBILE */

@media screen and (max-width: 540px) {
    /* font-family: 'Dela Gothic One', sans-serif;
font-family: 'Roboto', sans-serif; */




    body {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        overflow: hidden !important;

    }

    .cube-container {
        flex-direction: column;
    }




    .span {
        display: inline-block;
    }


    .threecontainer {
        height: 100vh;
        width: 100vw;
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        z-index: -100;
        overflow: hidden;
    }

    .threecontainer canvas {
        height: 110vh;
        width: 100vw;
    }



    .stream-video {
        position: absolute;
        left: 50%;
        top: 50%;
        display: flex;
        transform: translate(-50%, -50%);
        width: 100vw;
        height: 100vh !important;
        flex-direction: column;
        justify-content: center;
        gap: 10vh;
    }


    .image-container-landing {
        position: relative;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(100px);
        border-radius: calc(2rem + 6vw);
        scale: 0.1;
        border: 2px solid rgb(46, 46, 46);
        transition: background 0.2s linear;
    }

    .image-container-landing img {

        padding: 10px;
        max-height: 15vw;
        max-width: 15vw;
    }


    #marquee-container {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgb(46, 46, 46);

    }

    .image-container-landing {

        position: relative;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(100px);
        border-radius: calc(0.8rem + 1vw);
        scale: 0.85;
        border: 2px solid rgb(46, 46, 46);
        transition: background 0.2s linear;
    }


    .image-container-landing:active {
        background-color: rgba(255, 255, 255, 1);
    }

    .image-container-landing img {
        padding: 10px;
        max-height: 20vw;
        max-width: 20vw;
    }

    .iban-landing-img {
        scale: 0.75;
    }

    .iban-info-img {
        scale: 0.65;
    }

    .image-container-landing:nth-of-type(1) {
        transform: translateX(0);
    }

    .image-container-landing:nth-of-type(2) {
        transform: translateX(0);
    }

    .image-container-landing::before {

        font-size: calc(0.5rem + 0.5vw);
        font-family: var(--main-font);
        position: absolute;
        height: 0%;
        width: 150%;
        border: 2px solid var(--black);
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(100px);
        text-align: center;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(20px + 5vw);
        transition: background 0.2s linear;
    }

    #marquee-container {
        position: fixed;
        z-index: 10000;
        width: 100vw;
        overflow: hidden;
        white-space: nowrap;
        background-color: rgb(46, 46, 46);
        height: calc(2rem + 3vh);
    }

    #marquee-content {
        z-index: 10000;
        padding-top: 5px;
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        gap: 20vw;
        padding-left: 100%;
        width: 100%;
        animation: marquee 60s linear infinite;
        color: white;
        font-family: var(--marquee-font), sans-serif;
        font-size: calc(1vw + 0.8rem);
        font-weight: 800;
        text-transform: uppercase;
    }

    .reddot {
        height: calc(0.6vw + 0.3rem);
        width: calc(0.6vw + 0.3rem);
    }

    @keyframes marquee {
        from {
            transform: translateX(0);
        }

        to {
            transform: translateX(-400%);
        }
    }



    /* footer */

    .footer-button {
        border-radius: none !important;
        outline: none !important;
        border: 1px solid rgb(46, 46, 46);
        padding: 10px 8px;
        background-color: rgba(255, 255, 255, 0.4);
        font-family: var(--main-font);
        color: var(--black);
        backdrop-filter: blur(100px);
        width: calc(6rem + 10vw);
        height: 2.5rem;
        font-size: calc(0.5rem + 0.4vw);
        text-transform: uppercase;
        transition: color 0.2s linear, background-color 0.2s linear, transform 0.1s linear, box-shadow 0.1s linear;
        border-radius: 20px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        scale: 0.8;
    }

    .footer-button:hover {
        transform: translateY(0vh) translateX(0vw);
        box-shadow: 0px 0px 0px rgb(46, 46, 46);
    }

    footer {
        position: relative;
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 3vw 6vh 3vw;
        height: 6.5rem;
        top: 75vh !important;
        bottom: auto;

    }

    .footer-right {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 30%;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;

    }

    .footer-left {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 30%;
        gap: 5px;
        align-items: center;
        justify-content: flex-end;

    }

    footer p {
        font-family: var(--main-font), sans-serif;
        font-size: calc(0.4rem + 0.4vw);
        font-weight: 600;
        text-align: center;
        display: flex;
        align-items: center;
        max-width: calc(3rem + 4.5vw);
    }

    /* overlay */
    .overlay {}

    .overlay-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 10rem 0;
    }

    .overlay h1 {
        font-size: calc(1.3rem + 1.5vw);
        letter-spacing: calc(-1*(0.1rem));
        width: 100%;
        transform: translateY(1rem);
        text-align: center;
        margin-top: 12rem;
    }

    .overlay-anchor-container {

        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: calc(1rem + 2vh) 0px;
        gap: calc(1rem + 2vw);
        transform: translateY(calc((1rem + 2vh)/2));

    }

    .overlay h2 {
        font-family: Arial, Helvetica, sans-serif;
        width: 75%;
        text-align: center;
        font-size: 0.8rem;
        line-height: 23px;
        font-weight: 300;
    }

    .topic-anchor {
        font-size: 0.8rem;
        padding: 10px 0;
        height: 60%
    }

    .img-container {
        height: calc(5rem + 5vh);
        width: 70%;
        display: flex;
        justify-content: center;
        gap: 10px;
        transform: translateY(0.8vh);

    }

    .img-container img {
        height: calc(4rem + 6vw);
        width: calc(4rem + 6vw);
    }

    .image-div-for-before {
        height: calc(4rem + 6vw);
        display: flex;
        justify-content: center;
        transition: transform 1s cubic-bezier(0.3, -0.5, 0.2, 1);

    }

    .image-div-for-before::before {

        font-size: calc(0.6rem + 0.2vw);

    }

    .copied-container {

        height: calc(4rem + 6vw);
        width: calc(4rem + 6vw);
    }

    .landing-copied-container p {

        font-size: calc(1rem + 2vw);

    }
}